





































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import ChevronBullet from '@/app/ui/assets/ics_f_chevron_up_bullet.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import {
  IHeaderCell,
  ITableCell,
  ITimeSlot,
} from '@/data/infrastructures/misc/interfaces/pickupTimeManagement'
import {
  PickupTimeDetail,
  TimeSlot,
} from '@/domain/entities/PickupTimeManagement'
import Button from '@/app/ui/components/Button/index.vue'
import controller from '@/app/ui/controllers/PickupTimeManagementController'
import { Utils } from '@/app/infrastructures/misc/Utils'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import ExpandIcon from '@/app/ui/assets/chevron_down.vue'
import CalendarIcon from '@/app/ui/assets/ics_o_calendar.vue'
import CheckBox from '../../../Payroll/components/CheckBox/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import WarningCircleIcon from '@/app/ui/assets/ics_f_warning_circle_red.vue'

@Component({
  components: {
    DataTableV2,
    Button,
    ChevronBullet,
    DateTimePickerV2,
    ExpandIcon,
    CalendarIcon,
    CheckBox,
    LoadingOverlay,
    Badge,
    WarningCircleIcon,
  },
})
export default class PickupTimeManagementDetail extends Vue {
  controller = controller

  days = ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu']

  timeSlotData: ITimeSlot[] = []
  detailData: PickupTimeDetail = {}

  timeSlotHeaders = [
    this.headerCellMapper('No', '60px'),
    this.headerCellMapper('Waktu Mulai', '160px'),
    this.headerCellMapper('Waktu Berakhir', '160px'),
    this.headerCellMapper('Batas Waktu Pickup (Menit)', ''),
  ]

  created(): void {
    this.fetchDetail()
  }

  get isDefaultTimeSlot(): boolean {
    return !controller.pickupTimeDetailData.city
  }

  private fetchDetail(): void {
    const id = this.$route.params.id
    this.controller.getDetailPickupTime(parseInt(id))
  }

  public onCollapse(index: number): void {
    this.timeSlotData = this.timeSlotData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          state: !item.state,
        }
      }

      return item
    })
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  @Watch('controller.pickupTimeDetailData')
  onPickupTimeDetailDataChange(data: PickupTimeDetail): void {
    this.detailData = {
      ...data,
      createdAt: data.createdAt
        ? Utils.formatDateWithIDLocale(
            <string>data.createdAt,
            'DD MMMM YYYY, HH:mm'
          )
        : '-',
      updatedAt: data.updatedAt
        ? Utils.formatDateWithIDLocale(
            <string>data.updatedAt,
            'DD MMMM YYYY, HH:mm'
          )
        : '-',
      periodeStart: Utils.formatDateWithIDLocale(
        <string>data.periodeStart,
        'DD/MM/YYYY'
      ),
      periodeEnd: Utils.formatDateWithIDLocale(
        <string>data.periodeEnd,
        'DD/MM/YYYY'
      ),
    }

    this.timeSlotData =
      data.timeSlotPickup?.map((item, index) => {
        const data = item[Object.keys(item)[index] as keyof TimeSlot]
        return {
          day: {
            status: data?.status,
            text: data?.status === 'active' ? 'Aktif' : 'Nonaktif',
            type: data?.status === 'active' ? 'success' : 'error',
            isDisabled: data?.isDisabled,
          },
          slots: data?.slot?.map((timeSlot, i) => {
            return [
              this.tableCellMapper(i + 1 + '.', '60px'),
              this.tableCellMapper(
                Utils.formatDateWithIDLocale(
                  new Date(
                    `${Utils.formatDateWithIDLocale(
                      new Date().toISOString(),
                      'YYYY-MM-DD'
                    )} ${timeSlot.startTime}${Utils.forceTimeZoneAsiaJakarta()}`
                  ).toISOString(),
                  'HH:mm'
                ),
                '160px'
              ),
              this.tableCellMapper(
                Utils.formatDateWithIDLocale(
                  new Date(
                    `${Utils.formatDateWithIDLocale(
                      new Date().toISOString(),
                      'YYYY-MM-DD'
                    )} ${timeSlot.endTime}${Utils.forceTimeZoneAsiaJakarta()}`
                  ).toISOString(),
                  'HH:mm'
                ),
                '160px'
              ),
              this.tableCellMapper(<number>timeSlot.pickupTimeLimit, ''),
            ]
          }),
          state: index === 0 ? true : false,
        }
      }) || []
  }
}
